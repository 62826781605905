/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
import "swiper/css/pagination";

import SwiperCore, { Pagination } from "swiper/core";
import truck_1 from "../../assets/truck_1.jpg";
import truck_2 from "../../assets/truck.png";
import truck_3 from "../../assets/truck_1.jpg";
import truck_7 from "../../assets/truck_7.jpg.png";
import gallary_1 from "../../assets/gallary_1.jpg";
import gallary_2 from "../../assets/gallary_2.jpg";
import gallary_3 from "../../assets/gallary_3.jpg";
import footer from "../../assets/footer.png";
import "../home/Style.css";
import { Navigation } from "swiper";

SwiperCore.use([Pagination]);

const HomeSectionFive = () => {
  const data = [
   
    {
      src: gallary_2,
    },
    {
      src: gallary_1,
    },
    {
      src: truck_2,
    },
    
  ];

  const isMobile = useMediaQuery({ maxWidth: 738 });
  const shouldShowPagination = isMobile;

  const [selectedImage, setSelectedImage] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const storedSlideIndex = localStorage.getItem("activeSlideIndex");
    if (storedSlideIndex !== null) {
      setActiveSlide(parseInt(storedSlideIndex));
    }
  }, []);

  const handleImageClick = (index) => {
    setSelectedImage(data[index].src);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setSelectedImage(null);
    setPopupOpen(false);
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
    localStorage.setItem("activeSlideIndex", swiper.activeIndex);
  };

  const swiperParams = {
    slidesPerView: isMobile ? 1 : 3,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      clickable: true,
      el: shouldShowPagination && ".swiper-pagination",
      type: "bullets",
      bulletClass: "swiper-pagination-bullet",
      bulletActiveClass: "swiper-pagination-bullet-active",
      renderBullet: function (index, className) {
        return `<span class="${className}"></span>`;
      },
    },
    onSwiper: (swiper) => handleSlideChange(swiper),
    onSlideChange: (swiper) => handleSlideChange(swiper),
    initialSlide: activeSlide,
  };
  console.log(isMobile, "isMobile");
  return (
    <div className="" id="homesectionfive">
      <div className="container mx-auto px-4 py-10 md:px-6 lg:px-20 md:py-20">
        <div className="flex items-center justify-between">
          <div className="">
            <h1 className="animate__animated animate__fadeIn text-[white] font-bold text-lg md:text-4xl pb-6 ">
              Gallery
            </h1>
          </div>
        </div>

        <div className="Right md:flex items-center w-full h-full">
          {isMobile === true ? (
            <Swiper
              {...swiperParams}
              modules={[Pagination]}
              pagination={{ clickable: true }}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="flex gap-16 rounded-sm overflow-hidden shadow-xl">
                    <img
                      src={item.src}
                      className="animate__animated animate__pulse  w-[30rem] md:w-[40rem] h-[20rem] object-contain md:object-fit cursor-pointer"
                      onClick={() => handleImageClick(index)}
                      alt="Image"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              {...swiperParams}
              navigation={true}
              modules={[Navigation, Pagination]}
              pagination={{ clickable: true }}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="flex gap-16 rounded-sm overflow-hidden shadow-xl">
                    <img
                      src={item.src}
                      className="animate__animated animate__pulse w-[30rem] md:w-[40rem] h-[20rem] cursor-pointer object-contain md:object-fit"
                      onClick={() => handleImageClick(index)}
                      alt="Image"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          {/* {shouldShowPagination && (
            <div className="swiper-pagination mt-2 flex justify-center">
              {Array.from({ length: data.length }).map((_, index) => (
                <span
                  key={index}
                  className={`swiper-pagination-bullet ${
                    index === activeSlide
                      ? "swiper-pagination-bullet-active"
                      : ""
                  }`}
                ></span>
              ))}
            </div>
          )} */}
        </div>

        {popupOpen && (
          <div
            className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-50 flex items-center justify-center"
            onClick={handlePopupClose}
          >
            <div className="">
              <img
                src={selectedImage}
                className="w-full h-full  rounded-lg animate__animated animate__zoomIn object-cover"
                alt="Zoomed In"
              />
              <button
                className="absolute top-4 right-6 bg-black w-10 h-10 p-1 text-center text-white text-xl"
                onClick={handlePopupClose}
              >
                X
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeSectionFive;
