import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import rc_logo from "../assets/rc_logo.png";
import SwiperCore, { Pagination } from "swiper";
import HomeSectionSecond from "../components/home/HomeSectionSecond";
import HomeSectionThree from "../components/home/HomeSectionThree";
import HomeSectionFive from "../components/home/HomeSectionFive";
import HomeSectionSeven from "../components/home/HomeSectionSeven";
import HomeSectionEight from "../components/home/HomeSectionEight";
import bg_black from "../assets/redBlack_background.jpg";
import "swiper/swiper.min.css";
import "animate.css/animate.min.css";
import HomeSectionOne from "../components/home/HomeSectionOne";
import BottomFooter from "../components/footer/BottomFooter";
import "../App.css";
SwiperCore.use([Pagination]);

const Home = () => {
  const sectionStyle = {
    backgroundImage: `url(${bg_black})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [navbarClass, setNavbarClass] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setNavbarClass("bg-black text-white");
      } else {
        setNavbarClass("");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={sectionStyle} className="">
      <nav
        className={`sticky top-0 z-50 ${navbarClass} hidden md:block  peer-optional:`}
      >
        <div className="md:px-6 lg:px-20 container mx-auto flex justify-between items-center  text-white ">
          <div className="flex justify-center items-center">
            <a className="animate__animated animate__fadeIn" href="##">
              <Link to="main" smooth={true} duration={1000}>
                <img src={rc_logo} className="w-96 md:w-40 lg:w-96" alt="Logo" />
              </Link>
            </a>
          </div>
          <div className="flex items-center md:gap-10 lg:gap-16 py-1 ">
            <Link to="homeSectionSecond" smooth={true} duration={1000}>
              <p className="navLink italic font-semibold animate__animated animate__fadeIn cursor-pointer  ">
                {" "}
                Why Choose Us
              </p>
            </Link>
            <Link to="homesectionseven" smooth={true} duration={1000}>
              <p className="navLink italic font-semibold animate__animated animate__fadeIn cursor-pointer ">
                About Us
              </p>
            </Link>

            <Link to="homesectionfive" smooth={true} duration={1000}>
              <p className="navLink italic font-semibold animate__animated animate__fadeIn cursor-pointer">
                Gallery
              </p>
            </Link>

            <div className="">
              <Link to="homeeight" smooth={true} duration={1000}>
                <button className="navLink italic font-semibold animate__animated animate__fadeIn bg-[#DB1B29] font-semibold text-white p-1 md:p-2 lg:px-4 lg:py-2 inline-block transform skew-x-[-6deg] capitalize text-xs md:text-sm lg:text-lg">
                  get estimation
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className={`sticky top-0 z-50 ${navbarClass}  md:hidden  peer-optional:`}
      >
        <div className="flex justify-between items-center px-4  md:hidden">
          <div className="flex items-center gap-2">
            <button className="text-white" onClick={toggleSidebar}>
              {isOpen ? (
                <MdClose className="w-6 h-6" />
              ) : (
                <FiMenu className="w-6 h-6" />
              )}
            </button>
            <div className="flex justify-center items-center">
              <a className="animate__animated animate__fadeIn" href="##">
                <Link to="main" smooth={true} duration={1000}>
                  <img src={rc_logo} className="w-48" alt="Logo" />
                </Link>
              </a>
            </div>
          </div>

          <div className="">
            <Link to="homeeight" smooth={true} duration={1000}>
              <button className="animate__animated animate__fadeIn bg-[#DB1B29] font-semibold text-white p-1 md:p-2 lg:px-4 lg:py-2 inline-block transform skew-x-[-10deg] capitalize text-xs md:text-sm lg:text-lg">
                get estimation
              </button>
            </Link>
          </div>
        </div>
        <ul
          className={`fixed top-0 right-0 h-full bg-black text-white overflow-y-scroll transform transition-all duration-300 ease-in-out z-50 ${
            isOpen ? "translate-x-0 w-1/2" : "-translate-x-full w-0"
          }`}
        >
          <li className="p-2 pt-6">
            <Link to="homeSectionSecond" smooth={true} duration={1000}>
              <p className="animate__animated animate__fadeIn px-2 cursor-pointer">
                {" "}
                Why Choose Us
              </p>
            </Link>
          </li>
          <li className="p-2">
            <Link to="homesectionseven" smooth={true} duration={1000}>
              <p className="animate__animated animate__fadeIn px-2 cursor-pointer">
                About Us
              </p>
            </Link>
          </li>
          <li className="p-2">
            <Link to="homesectionfive" smooth={true} duration={1000}>
              <p className="animate__animated animate__fadeIn px-2 cursor-pointer">
                Gallery
              </p>
            </Link>
          </li>
        </ul>
      </nav>
      <HomeSectionOne />
      <HomeSectionSecond />
      <HomeSectionSeven />
      <HomeSectionFive />
      <HomeSectionThree />
      <HomeSectionEight />
      <BottomFooter />
    </div>
  );
};

export default Home;
