import React from "react";
// import About_3 from "../../assets/truck_7.jpg.png"
import { Link } from "react-scroll";
import About_3 from "../../assets/about_4.png";
// import { Link } from "react-router-dom";
const HomeSectionSeven = () => {
  return (
    <div className=" " id="homesectionseven">
      <div className=" container  mx-auto relative  md:py-10 px-4 md:px-6 lg:px-20  flex justify-center ">
        <div className="md:flex gap-10 p-8  bg-white rounded-2xl shadow-md">
          <div className="md:w-4/6  ">
            <img
              src={About_3}
              className=" h-[300px] md:h-[550px]  object-contain  md:object-cover md:rounded-2xl"
            />
            <div className="w-4/6 ">
            <button className="animate__animated animate__fadeIn absolute text-xs md:text-lg left-28 -mt-5 md:left-10 lg:left-72  md:-mt-2 p-1   md:p-2  bg-[#DB1B29] font-semibold text-white  inline-block rounded-lg capitalize ">
            "A decade of automobile experience"
            </button>
            </div>
           
          </div>
          <div className="py-6 md:py-0 md:w-4/6 flex flex-col">
            <p className="animate__animated animate__fadeIn text-[#C93433] font-bold">
              ABOUT US
            </p>
            <h1 className="animate__animated animate__fadeIn text-[#022862] font-bold md:text-xl transform capitalize py-2 md:py-4">
              Get To Know About Us Briefly
            </h1>
            <p className="animate__animated animate__fadeIn  py-2 ">
            RC Mobility is a subsidiary of Race Concepts. Race Concepts is 
            part of the motorsport industry for the last 12+ years and we understand the importance 
            of safe and secure transportation of cars. Our modern enclosed trailer trucks are well equipped
             and custom built to handle a wide spectrum of race cars, rally cars, exotic cars, race bikes, super bikes,
              special projects and can accommodate vehicles up to 20 feet long. The custom-built container body is water
               and dust resistant. We provide weekend packages for Motorsport events to transport race cars, 
            rally cars and race bikes with safe overnight parking. 
            </p>
            <p className="animate__animated animate__fadeIn py-2">
            Our trucks are equipped with a rear opening hydraulic ramp which 
            has a very low approach angle to load cars having minimal ground clearance and remote 
            operated winch to load and unload the vehicle. The Truck has live GPS tracking to ensure your valuable
             automobile is in safe hands. Our experienced drivers and staff can provide
             you the best experience in ensuring we meet your expectations!
            </p>

            
            <div className="py-2 md:py-6">
            <Link  to="homeeight" smooth={true} duration={1000}>
            <button className="animate__animated animate__fadeIn border border-[#DB1B29] font-semibold text-[#DB1B29] px-4 py-2 inline-block transform skew-x-[-10deg] capitalize">
                get estimation
              </button>
            </Link>
            
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionSeven;
