import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import "./Style.css"

const BottomFooter = () => {
  return (
    <div className="  relative">
      <div className="container mx-auto px-4 md:px-10 lg:px-28 py-4">
        <div className="flex justify-center items-center text-white">
        {/* <div className="animate__animated animate__fadeIn bg-gradient-to-r from-green-400 to-green-500 p-2 rounded-lg absolute right-5 -top-4">
  <a href="https://wa.me/9945487653">
    <BsWhatsapp className="md:w-10 md:h-10" />
  </a>
</div> */}
 <a href="https://api.whatsapp.com/send?phone=9945487653&text=" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    </a>

          <div>
            <p className="animate__animated animate__fadeIn text-xs md:text-sm">
              All Rights Reserved By RC Mobility &copy; 2023{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomFooter;
