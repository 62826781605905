import React from 'react';
import help_cta_bg from '../../assets/rc_service.jpg';
import { Link } from 'react-scroll';

const HomeSectionThree = () => {
  const sectionStyle = {
    backgroundImage: `url(${help_cta_bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
   
    
  };

  

  return (
    <div style={sectionStyle} className='' >
      <div className=' container  mx-auto  text-md   md:py-16 md:flex justify-between items-center text-[white] px-4 md:px-6 lg:px-20  '>
        <h2 className='animate__animated animate__fadeIn font-semibold md:text-4xl py-1 md:py-2 text-[]' >
          NEW TO RC Mobility SERVICE? NEED HELP?
        </h2>
        <div className='py-2 md:py-0 '>
          <Link to='homeeight' smooth={true} duration={1000}>
          <button className='animate__animated animate__fadeIn border-2 border-[white] font-semibold text-[white]  p-1 md:p-2 inline-block transform skew-x-[-10deg] capitalize'>
           GET A QUOTE
          </button>
          </Link>
         
        </div>
      </div>
    </div>
  );
};

export default HomeSectionThree;
