import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";
import Truck_6 from "../../assets/truck png 2 (2).png";
import Truck_5 from "../../assets/truck png 2 (2).png";
import Truck_4 from "../../assets/truck png 1 (1).png";

const HomeSectionOne = () => {
  return (
    <div className=" flex justify-center items-center py-10 ">
      <div className=" px-4 md:px-6 lg:px-24">
        <div className="flex items-center flex-col  ">
          <div className="md:px-32">
            <h1 className="animate__animated animate__fadeIn text-sm md:text-xl text-[white] opacity-100 text-center font-bold py-2 ">
              <span style={{ color: "red" }}>
                Experience unparalleled Excellence{" "}
              </span>{" "}
              in Automobile Transportation with RC Mobility service - Elevating
              your Exotic, Project and Race car Transportation Experience to New
              Heights.
            </h1>
          </div>

          <div className="animate__animated animate__fadeIn w-10 h-10 my-4 flex justify-center items-center rounded-full bg-transparent text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </div>

          <div className="py-2 flex justify-center items-center w-full">
            <img
              src={Truck_5}
              className="animate__animated animate__fadeIn h-[20rem] md:h-[36rem]"
              alt="Truck"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionOne;
