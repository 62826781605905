import React,{useState} from "react";
import axios from 'axios';
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const HomeSectionEight = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = {};

  if (formData.name.trim() === "") {
    validationErrors.name = "Name is required";
  }

  if (formData.email.trim() === "") {
    validationErrors.email = "Email is required";
  } else if (!isValidEmail(formData.email)) {
    validationErrors.email = "Invalid email format";
  }

  if (formData.phone.trim() === "") {
    validationErrors.phone = "Phone number is required";
  } else if (!isValidPhoneNumber(formData.phone)) {
    validationErrors.phone = "Invalid phone number format";
  }

  if (formData.message.trim() === "") {
    validationErrors.message = "Message is required";
  }
  
    
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true); // Show loading icon
  
      try {
        const response = await axios.post('https://enquiry.rcmobility.in/send-email', formData);
        console.log(response.data);
  
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: ""
        });
        setErrors({});
        setLoading(false); // Hide loading icon
  
        toast.success("Submitted successfully!"); // Show toast notification
      } catch (error) {
        console.error(error);
        setLoading(false); // Hide loading icon
  
        toast.error("Submission failed. Please try again."); // Show toast notification
      }
    }
  };
  


  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    return /^\d{10}$/.test(phone);
  };
 
  return (
    <div className=" text-white" id="homeeight">
      <div className="container  mx-auto relative  md:py-20  px-4 py-10 md:px-6 lg:px-20  md:flex md:justify-center">
        <div className="md:w-2/6">
          <p className="animate__animated animate__fadeIn text-xl md:text-2xl lg:text-4xl font-bold my-4">Speak with our executive</p>
          <div className="flex items-center gap-6 md:py-6">
           
            <div className="animate__animated animate__fadeIn w-10 h-10 flex justify-center items-center rounded-full bg-[#C93433] text-black ">
           <a href="tel:+91 9945487653">
           <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
           </a>
              
            </div>

            <div>
              <p className="animate__animated animate__fadeIn md:text-lg font-bold">Call Us Now</p>
              <a href="tel:+91 9945487653">
              <p className="animate__animated animate__fadeIn">+91 9945487653</p>
              </a>
              
            </div>
          </div>
          <div className="flex items-center gap-6 py-2 md:py-6">
            <div className="animate__animated animate__fadeIn w-10 h-10 flex justify-center items-center rounded-full bg-[#C93433] text-black ">
              <a href="mailto:support@raceconcepts.in">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              </a>
              
            </div>

            <div>
              <p className="animate__animated animate__fadeIn md:text-sm lg:text-lg font-bold">Send Email</p>
              <a href="mailto:support@raceconcepts.in">
              <p className="animate__animated animate__fadeIn md:text-sm lg:text-lg">support@raceconcepts.in</p>
              </a>
              
            </div>
          </div>
        </div>
        <div className="md:w-4/6">
      <form onSubmit={handleSubmit}>
        <div className="animate__animated animate__fadeIn border border-white my-4 text-white px-4 py-2 inline-block transform skew-x-[-10deg] capitalize w-full">
          <input
            className="bg-black w-full outline-none"
            placeholder="Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          
        </div>
        {errors.name && <span className="text-red-500">{errors.name}</span>}
        <div className="animate__animated animate__fadeIn border border-white my-4 text-white px-4 py-2 inline-block transform skew-x-[-10deg] capitalize w-full">
          <input
            className="bg-black w-full outline-none"
            placeholder="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          
        </div>
        {errors.email && <span className="text-red-500">{errors.email}</span>}
        <div className="animate__animated animate__fadeIn border border-white my-4 text-white px-4 py-2 inline-block transform skew-x-[-10deg] capitalize w-full">
          <input
            className="bg-black w-full outline-none"
            placeholder="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          
        </div>
        {errors.phone && <span className="text-red-500">{errors.phone}</span>}
        <div className="animate__animated animate__fadeIn border border-white my-4 text-white px-4 py-2 inline-block transform skew-x-[-10deg] capitalize w-full">
          <textarea
            className="bg-black w-full outline-none"
            placeholder="Write Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          
        </div>
        {errors.message && <span className="text-red-500">{errors.message}</span>}
        <div className="">
    <button className="animate__animated animate__fadeIn bg-[#DB1B29] font-semibold text-white p-1 md:px-6 md:py-2 inline-block transform skew-x-[-6deg] capitalize text-xs md:text-lg w-2/6">
      {loading ? (
        <ClipLoader
          color={"#fff"}
          loading={loading}
          css={css`
            display: block;
            margin: 0 auto;
          `}
          size={20}
        />
      ) : (
        "Submit"
      )}
    </button>
  </div>
      </form>
      <ToastContainer 
      position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    
    </div>

      </div>
    </div>
  );
};

export default HomeSectionEight;
