import React from "react";
import Service_bg from "../../assets/services-bg.png";
import About_1 from "../../assets/about_1.png";
import "animate.css/animate.min.css";

const HomeSectionSecond = () => {
  const sectionStyle = {
    backgroundImage: `url(${Service_bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "400px",
  };

  return (
    <div className=" " id="homeSectionSecond">
      <div className="container  mx-auto relative px-4 md:px-6 lg:px-20   py-16    ">
        <h2 className="animate__animated animate__bounce text-white font-bold md:text-4xl py-2 capitalize  ">
          why choose us?
        </h2>
        <div className="md:flex md:gap-32 md:justify-center  py-10 w-full ">
          <div className="flex flex-col items-end">
            <div className="  my-6 flex justify-center items-center ">
              <div className=" animate__animated animate__fadeIn md:w-5/6 p-5 text-lg capitalize rounded-2xl   bg-white shadow-lg   ">
                <div className="  flex justify-center items-center font-bold text-center ">
                  <p>
                    {" "}
                    RC Mobility Service, your trusted partner for transporting
                    your valuable cars.
                  </p>
                </div>
              </div>
            </div>
            <div className="my-6 flex ">
              <div className="animate__animated animate__fadeIn md:w-5/6 p-5 text-lg capitalize rounded-2xl   bg-white shadow-lg   ">
                <div className=" flex justify-center items-center font-bold text-center  ">
                  <p>
                    we provide efficient and reliable transportation solutions
                    tailored to your specific needs at a very optimal price.
                  </p>
                </div>
              </div>
            </div>
            <div className="  my-6 flex  items-center ">
              <div className=" animate__animated animate__fadeIn md:w-11/12 p-5 text-lg capitalize rounded-2xl   bg-white shadow-lg   ">
                <div className="  flex justify-center items-center font-bold text-center ">
                  <p>
                    {" "}
                    Transport solutions for any motorsport events to suit custom needs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionSecond;
